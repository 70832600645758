#container {
	margin-top: 2rem;
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-gap: 1em;
	width: fit-content;
}

#profile-cover {
	align-self: start;
	width: 90px;
	height: 90px;
	object-fit: cover;
	margin-left: 30px;
	border-radius: 50%;
	grid-column: 1/3;
	grid-row: 1/3;
}

#profile-name {
	font-size: 15px;
	font-weight: 400;
	margin-left: auto;
}

#company-name {
	font-size: 15px;
	font-weight: 400;
	margin-left: auto;
}
#company-name:hover {
	text-decoration: underline;
	cursor: pointer;
}

#profile-info {
	margin-top: 10%;
}

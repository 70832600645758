#search-image-container {
    display: table-cell;
    align-items: center;
    vertical-align: middle;
}

#search-anaf-img {
    display:block;
    height: 100%;
    margin-top: 10px;
    margin-left: 3px;
}

#search-anaf-img {
    cursor: pointer;
}

#company-vat-on-payment-checkbox, #company-vat-payer-checkbox {
    width: 25px;
}

#company-page-table-title {
    padding-top: 40px;
    padding-bottom: 20px;
    font-weight: bold;
    font-size: large;
}

#company-page-table {
    border-collapse: collapse;
    width: 760px;
    min-width: 400px;
    -webkit-box-shadow: 0px 0px 4px 0px rgba(85,85,85,1);
    -moz-box-shadow: 0px 0px 4px 0px rgba(85,85,85,1);
    box-shadow: 0px 0px 2px 0px rgba(85,85,85,1);
    border-radius: 6px;
    font-size: 0.87rem;
}

#company-page-table-body-row, #company-page-table-head-row {
    border-bottom: 1px solid #cccccc;
    text-align: left;
}

#company-page-table-body-row:hover {
    background-color: #d6ebff;
}

#company-page-table-head-cell, #company-page-table-cell {
    padding-left: 8px;
}

.company-page-edit-icon, .company-page-download-icon {
    margin-left: 24px;
    height: 16px;
    width: 16px;
}

#company-page-form {
    box-shadow: 0px 0px 4px -1px rgba(0,0,0,0.75);
    padding-top: 8px;
    margin-bottom: 8px;
    width: 758px;
}

#name-input {
    width: 300px;
}

#address-input {
    width: 410px;
}

#work-point-form-inputs {
    display: flex;
}

#name-label, #address-label, #work-point-status-label {
    margin-left: 10px;
}

#work-point-status-dropdown {
    margin-left: 10px;
    margin-top: 10px;
    width: 150px;
}

#modify-work-point-button, #cancel-work-point-button {
    margin-left: 10px;
    margin-bottom: 8px;
}

#company-page-table td:nth-child(1) {
    width: 300px;
}

#company-page-table td:nth-child(2) {
    width: 410px;
}

#company-page-table td:nth-child(3) {
    width: 70px;
}

.company-logo-title {
    font-weight: bold;
    font-size: large;
    margin-top: 20px;
}
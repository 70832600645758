#profile-alert-container {
	display: grid;
	grid-template-columns: 15% 85%;
}

.alert-danger {
    margin-top: 10px;
	color: red;
	background-color: rgba(252, 98, 71, 0.1);
	border: 1px solid red;
	width: 350px;
	padding: 0;
}

.alert-success {
    margin-top: 10px;
    color: rgb(0, 145, 0);
    background-color: rgba(47, 212, 25, 0.1);
    border: 1px solid rgb(0, 145, 0);
    width: 350px;
    padding: 0;
}

.profile-alert-image {
	display: flex;
	justify-content: center;
	align-items: center;
}

.profile-alert-message p {
    margin: 0.6em 0;
}
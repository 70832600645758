#change-password-section {
    margin-top: 40px;
}

#change-password-section-title {
    margin-bottom: 10px;
}

#account-data-section {
    margin-top: 40px;
}


html {
    font-size: 1em;
}

body {
	margin: 0 !important;
	padding: 0 !important;
}

.App {
	font-family: 'Lato', sans-serif;
}

.etportal-page-container {
	padding: 30px;
    max-height: 100vh;
    overflow-y: auto;
}

.green-color {
    color: rgb(145, 194, 72) !important;
    font-weight: 600;
}

.red-color {
    color: red !important;
    font-weight: 600;
}

.orange-color {
	color: #e6bb22 !important;
}

.module-title {
    color:#011631;
    margin-top: 0px;
}

.header-fixed > thead,
.header-fixed > tbody,
.header-fixed > thead > tr,
.header-fixed > tbody > tr,
.header-fixed > thead > tr > th,
.header-fixed > tbody > tr > td {
    display: block;
}

.header-fixed > tbody > tr:after,
.header-fixed > thead > tr:after {
    content: ' ';
    display: block;
    visibility: hidden;
    clear: both;
}

.header-fixed > tbody {
    overflow-y: auto;
    height: 550px;
}

.et-section {
    -webkit-box-shadow: 0px 0px 4px -1px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 0px 4px -1px rgba(0,0,0,0.75);
    box-shadow: 0px 0px 4px -1px rgba(0,0,0,0.75);
    padding: 15px;
}

.et-section-no-shadow {
    padding: 15px;
}

.et-section-title {
    margin-top: 0px;
}

.et-button {
    background-color: #ffffff;
    color:#0164A5;
    border: 1px solid #0164A5;
    border-radius: 5px;
    height: 30px;
    margin-top: 15px;
    font-family: 'Lato', sans-serif;
}

.et-button-disabled {
    background-color: #fff;
    cursor: auto;
    border: 1px solid #aaa;
    color: #aaa;
    pointer-events: none;
}

.et-button:disabled,
button[disabled] {
    background-color: #fff;
    cursor: auto;
    border: 1px solid #aaa;
    color: #aaa;
    pointer-events: none;
}

.et-button:hover {
    background-color: #0164A5;
    color:#fff;
    cursor: pointer;
}

.et-button-danger {
    background-color: #ffffff;
    color: red;
    border: 1px solid red;
    border-radius: 5px;
    height: 30px;
    margin-top: 15px;
    font-family: 'Lato', sans-serif;
}

.et-button-danger:hover {
    background-color: red;
    color:#fff;
    cursor: pointer;
}

.et-button-no-borders {
    background-color: #ffffff;
    color:#0164A5;
    border: 0px;
    border-bottom: 2px solid #0164A5;
    height: 30px;
    margin-top: 15px;
    font-family: 'Lato', sans-serif;
}

.et-button-no-borders:hover {
    cursor:pointer;
}

.et-form-group {
    display: table-row;
}

.et-form-label {
    display: table-cell;
    font-size: 15px;
    margin-top: 10px;
}

.et-form-input {
    border: 1px solid #0164A5;
    border-radius: 5px;
    height: 20px;
    display: table-cell;
    margin-left: 10px;
    margin-top: 10px;
    width: 230px;
    font-size: 15px;
    padding: 2px 2px;
    padding-left: 5px;
}

.et-form-input:focus {
	outline: none;
    border: 1px solid #011631;
}

.et-form-selector {
    border-radius: 5px;
    height: 26px;
    width: 234px;
    font-size: 15px;
    border: 1px solid #0164A5;
    padding: 2px 2px;
}

.orders-reports-table-body-cell {
    padding: 0.4em;
    color:#666;
    float: left;
}

.orders-reports-table-head-cell {
    text-align:left;
    color: #aaa;
    padding: 0.4em;
    float: left;
}

.orders-cell {
    width: 12.5%;
}

.reports-cell {
    z-index: -1;
    width: 12.2%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.reports-cell-small {
    width: 6.5%;
}

.small-action-cell {
    width: 5% !important;
}

.medium-icon {
    width: 25px;
	height: 25px;
	align-self: center;
}

.small-icon {
    width: 18px;
    height: 18px;
    align-self: center;
}

.eclipse-text {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.red-text {
    color: red;
}

#table-container {
    margin-top: 20px;
}
.conveyor-reports-virtualized-table {
    margin-top: 20px;
    box-shadow: 1px 1px 1px 0px rgba(85,85,85,0.25);
}

.conveyor-reports-virtualized-table-container {
    min-height: 550px;
    height: 100%;
    max-height: 550px;
    border-radius: 6px;
    width: 820px;
    min-width: 820px;
}

.conveyor-reports-virtualized-table {
    border-radius: 6px;
    border: 1px solid #cccccc;
}

.conveyor-reports-virtualized-table > div > table {
    border-collapse: collapse;
    font-size: 0.87rem;
    width: 100%;
}

.conveyor-reports-virtualized-table > div > table > thead {
    z-index: 0 !important; 
    background-color: white;
    border-bottom: 1px solid #cccccc;
}

.conveyor-reports-virtualized-table > div > table > thead > tr {
    display: block;
}

.conveyor-reports-virtualized-table > div > table > thead > tr > th {
    z-index: 0 !important;
    background-color: white;
    width: 130px;
    min-width: 130px;
    display: block;
    text-align:left;
    color: #666;
    padding: 0.4em;
    float: left;
    z-index: -1;
}

.conveyor-reports-virtualized-table > div > table > tbody > tr {
    display: block;
    z-index: -1 !important;
    text-align: left;
    align-content: center;
    border-bottom: 1px solid #cccccc;
}

.conveyor-reports-virtualized-table > div > table > tbody > tr > td {
    height: 21px;
    text-align: left;
    align-content: center;
    width: 130px;
    min-width: 130px;
    padding: 0.4em;
    color: #666;
    float: left;
}
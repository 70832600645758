#popup-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0,0,0,0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
}

#popup-inner-content {
    position: relative;
    padding: 20px;
    width: 500px;
    max-width: 640px;
    background-color: #fff;
    border-radius: 10px;
    text-align: justify
}

#popup-title {
    margin: 0px;
}

#popup-buttons-yes-no-container {
    display: flex;
    justify-content: space-between;
}
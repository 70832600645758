.react-datepicker__input-container > input {
    padding: 0.3em;
}

.react-datepicker__input-container span {
    padding-top: 0.3em;
}

#timerange-filter {
    min-width: 811px;
    margin-top: 8px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

#timerange-filter label {
    margin-right: 0.5em;
}

.timerange-radio-input {
    margin-bottom: 0.1em;
}

#align-start-date-picker {
    display: inline-block;
}

.disabled-div {
    pointer-events: none;
    opacity: 0.6;
}

#datepicker-container {
    flex-direction: row;
}
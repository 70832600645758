.invoice-table-body-row, .invoice-table-head-row {
    border-bottom: 1px solid #cccccc;
    display: grid; 
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr; 
}

.invoice-table-body-row:hover {
    -webkit-box-shadow: inset 0px -1px 2px 0px rgba(169,212,252,1);
    -moz-box-shadow: inset 0px -1px 2px 0px rgba(169,212,252,1);
    box-shadow: inset 0px -1px 2px 0px rgba(169,212,252,1);
    color: #40a3ff !important;
}

#invoice-success-upload-icon {
    height: 16px;
}

#invoice-fail-upload-icon {
    height: 16px;
}

.invoice-edit-icon {
    height: 16px;
    width: 16px;
}

.hide-class {
    display: none;
}

.pay-invoice-icon {
    height: 16px;
}

.invoice-cancel-icon {
    height: 16px;
}
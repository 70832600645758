#access-token-input {
    width: 600px;
}

#save-token-button {
    margin-left: 20px;
}

#token-section-title {
    margin-bottom: 0px;
}

#automatically-refresh-token {
    height: 16px;
    width: 16px;
}
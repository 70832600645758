.profile-row-details-container {
    margin-bottom: 20px;
    margin-top: 1.33em;
}

.profile-details-form {
    max-width: 500px !important;
}

.data-form-group {
    display:block !important;
}

.profile-data-input {
    margin-left: 0 !important;
    width: 200px !important;
    padding: 10px !important;
    border-radius: 3px !important;
    margin-top: 5px !important;
}

.profile-form-label {
    font-size: 14px !important;
}

#cancel-button {
    margin-left: 20px;
}

#form-group-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
}
/*Base CSS*/
.et-icon {
    height: 20px;
    width: 20px;
    margin-right: 2px;
}

.et-icon-tooltip-text {
    visibility: hidden;
    background-color: #011631;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px;
    position: absolute;
    z-index: 1000;
    margin-left: -12px;
    width: max-content;
    opacity: 0;
    transition: opacity 0.5s;
}

/*On hover*/
.et-icon:hover .et-icon-tooltip-text {
    visibility: visible;
    opacity: 1;
}

.et-icon img {
    margin-left: 2px;
    margin-top: 2px;
}

.et-icon:hover {
    cursor: pointer;
    border-radius: 4px;
    background:rgb(230, 230, 230);
}
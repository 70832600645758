#update-entity-form {
    padding: 16px;
    box-shadow: 0px 0px 4px -1px rgba(0,0,0,0.75);
    max-width: 1000px;
}

#update-entity-header {
    display: flex;
}

#update-entity-header > div > label {
    padding-left: 10px;
}

#update-entity-form-footer > input {
    margin-left: 10px;
}
#menu-container {
	background-color: #011631;
	height: 100%;
	color: #ffffff;
	-webkit-box-shadow: 1px 0px 3px 0px rgba(0, 0, 0, 0.75);
	-moz-box-shadow: 1px 0px 3px 0px rgba(0, 0, 0, 0.75);
	box-shadow: 1px 0px 3px 0px rgba(0, 0, 0, 0.75);
	position: relative;
}

#menu-list {
	padding: 0;
	list-style-type: none;
}

.menu-item {
	font-size: 1.1rem;
	font-weight: 500;
	border-bottom: 1px solid #fff;
	height: 2.5rem;
	display: flex;
	justify-content: center;
	align-content: center;
	flex-direction: column;
}

.menu-item:hover {
	background-color: #2f3c4e;
	cursor: pointer;
}

.selected {
	background-color: #2f3c4e;
}

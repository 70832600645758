.profile-row {
    display: grid;
    grid-template-columns: 1fr 3fr 1fr;
    border-bottom: 1px solid #cccccc;
}

.row-action {
    margin-left: auto;
    margin-right: 0;
}

.displayed-value {
    margin-top: 1.33em;
}
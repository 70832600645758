#login-page-container {
	height: 100vh;
	display: grid;
	grid-template-columns: 28% 72%;
}

#login-panel {
	padding: 10vh;
	padding-left: 20vh;
}

#login-image {
	object-fit: cover;
	min-height: 100vh;
	max-width: 100%;
}

#login-title {
	color: #0164A5;
}

#login-subtitle {
	color: rgba(204, 204, 204, 1);
	margin-top: 15vh;
	font-weight: 400;
}

#image-div {
	max-height: 100vh;
}

#login-form {
	margin-top: 3vh;
}

.form-group {
	margin-bottom: 15px;
}

.login-form-input {
	width: 400px;
	height: 27px;
	margin-top: 5px;
	border: 0;
	border-bottom: 2px solid #0164A5;
}

.login-form-input:focus {
	outline: none;
}

#login-button {
	margin-top: 7vh;
	margin-left: 260px;
	height: 50px;
	width: 140px;
	font-size: 17px;
	font-weight: bold;
	background-color: #0164A5;
	color: white;
	border: 0;
	border-radius: 5px;
}

#login-button:hover {
	cursor: pointer;
	-webkit-box-shadow: 1px 2px 3px 0px rgba(204, 204, 204, 1);
	-moz-box-shadow: 1px 2px 3px 0px rgba(204, 204, 204, 1);
	box-shadow: 1px 2px 3px 0px rgba(204, 204, 204, 1);
	background-color: #011631;
}

#login-form label {
	font-size: 17px;
	font-weight: 600;
	color: #011631;
}

*::placeholder {
	/* modern browser */
	color: rgba(204, 204, 204, 1);
}

.login-form-input,
label {
	display: block;
}

#purchase-orders-table {
    border-collapse: collapse;
    width: 100%;
    min-width: 1140px;
    -webkit-box-shadow: 0px 0px 4px 0px rgba(85,85,85,1);
    -moz-box-shadow: 0px 0px 4px 0px rgba(85,85,85,1);
    box-shadow: 0px 0px 2px 0px rgba(85,85,85,1);
    border-radius: 6px;
    font-size: 0.87rem;
}

#purchase-orders-table-body {
    display: block;
    overflow-y: auto;
    overflow-x: hidden;
    table-layout: fixed;
}

.purchase-orders-table-body-row, .purchase-orders-table-head-row {
    border-bottom: 1px solid #cccccc;
}

.purchase-orders-table-body-row:hover {
    background-color: #d6ebff;
}

.purchase-orders-show-more-icon {
    height: 16px;
    width: 16px;
}

.purchase-orders-show-more-icon:hover {
    cursor: pointer;
}

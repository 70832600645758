#show-invoice-form-button {
    margin-top: 0px !important;
}

#invoice-table {
    border-collapse: collapse;
    width: 100%;
    min-width: 1140px;
    -webkit-box-shadow: 0px 0px 4px 0px rgba(85,85,85,1);
    -moz-box-shadow: 0px 0px 4px 0px rgba(85,85,85,1);
    box-shadow: 0px 0px 2px 0px rgba(85,85,85,1);
    border-radius: 6px;
    font-size: 0.87rem;
}

#invoice-table-body {
    display: block;
    overflow-y: auto;
    overflow-x: hidden;
    table-layout: fixed;
}

.scale-reports-show-more-icon:hover {
    cursor: pointer;
}

#invoice-loading-icon {
    height: 18px;
    width: 18px;
}

#apply-filters-button {
    margin: 0px;
    margin-right: 0;
    margin-left: auto;
    display: block;
}

#reports-filters-form {
    display: grid;
    grid-template-columns: 6fr 1fr;
    min-width: 1140px;
    margin-top: 0.5em;
}

#active-filters-panel {
    margin-top: 1.5em;
}

.filters-title {
    margin: 0px;
}

#applied-filters-container {
    display: grid;
    grid-template-columns: 350px 350px 350px;
}

#user-section {
    margin-top: 30px;
}

.adminPanel-form-selector {
    display: table-cell;
    margin-top: 10px;
    margin-left: 10px;
    border-radius: 5px;
    height: 26px;
    width: 90%;
    font-size: 15px;
    border: 1px solid #0164A5;
    padding: 2px 2px;
}


.in-form-label{
    margin-left: 10px;
}

hr.dotted {
    border-top: 1px dotted rgb(56, 56, 56);
    margin-bottom: 10px;
}

.invoice-cell {
    width: 10%;
}

.invoice-cell-small {
    width: 5%;
}

.display-flex {
    display: flex;
}
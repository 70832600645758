#logout-section-wrapper {
	position: absolute;
	bottom: 0;
	width: 90%;
}

#logout-container {
	width: 100%;
	padding-left: 10%;
	display: grid;
	grid-template-columns: 1fr 7fr;
}

#logout-container:hover {
	cursor: pointer;
	background-color: #2f3c4e;
}

#logout-icon {
	width: 25px;
	height: 25px;
	align-self: center;
}
#logout-text {
	margin-left: 10px;
	font-size: 20px;
	font-weight: 400;
	padding-bottom: 2px;
}

#logout-container:hover {
	cursor: pointer;
}

#icons8-reference {
	padding-left: 10px;
	color: #516279;
	font-size: 13px;
}

#icons8-reference a {
	color: inherit;
	text-decoration: none;
}

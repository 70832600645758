#overview-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    width: 100%;
    min-width: 600px;
    margin-bottom: 10px;
}

#line-charts-container, #second-level-container {
    display:grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 15px;
    height: 300px;
    min-width: 1050px;
}

.line-chart {
    height: 250px;
}

.waste-management-section-title {
    margin-top: 5px;
}

#doughnuts-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
}

.dough-container {
    margin-top: 50px;
    height: 120px;
    width: 120px;
    padding-left: 10px;
}

.doughnut-title {
    text-align: center;
}

.bar-chart {
    height: 250px;
}


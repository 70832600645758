.overview-el-container {
    padding: 15px;
    border-left: 3px solid #ccc;
}

.overview-content {
    margin: 0px;
}

.overview-title {
    margin: 0px;
}
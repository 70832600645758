#show-form-container {
    min-width: 1140px;
}

#invoice-header-data-container-1 {
    width: 100%;
}

.invoice-header-data {
    display: inline-block;
    height: 50px;
    margin-right: 0.5%;
}

.invoice-header-label {
    margin-bottom: 3px;
}

.invoice-text-input {
    margin-top: 0px !important;
    margin-left: 0px !important;
    font-size: 13px !important;
}

#invoice-client-name-container {
    width: 25%;
}

#invoice-number-container, #invoice-series-container {
    width: 10%;
}

#invoice-client-tax-code-container {
    width: 20%;
}

#invoice-client-reg-code-container {
    width: 14.5%;
}

#invoice-client-address-container {
    width: 40%;
}

#invoice-client-iban-container {
    width: 28%;
}

#tax-code-elements-container {
    display: flex;
    align-items: center;
}

#search-icon:hover {
    cursor: pointer;
}

#invoice-date-container, #invoice-due-date-container {
    width: 10%;
}

#invoice-currency-container {
    width: 6%;
}

#client-name-input, #client-tax-code-input, #number-input, #series-input, #client-address-input, #client-iban-input, #client-reg-code-input {
    width: calc(100% - 10px) !important;
}

.invoice-selector, #invoice-header-data-container-1 .react-datepicker-wrapper {
    width: 100% !important;
}

.react-datepicker__input-container input {
    width: calc(100% - 10px) !important;
}


#footer-1 {
    width: 100%;
}

#add-product-container {
    width: 10%;
}

#totals-container {
    margin-left: 10%;
    width: 700px;
}

.total-span {
    margin-left: 10%;
}

.footer-1-child {
    display: inline-block;
}

#cancel-invoice-button {
    margin-left: 10px;
}
#alert-container {
	display: grid;
	grid-template-columns: 15% 85%;
}

#alert-image {
	display: flex;
	justify-content: center;
	align-items: center;
}

.alert-danger {
	color: red;
	background-color: rgba(252, 98, 71, 0.1);
	border: 1px solid red;
	width: 400px;
	padding: 0;
}

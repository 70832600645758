#scale-reports-table {
    border-collapse: collapse;
    width: 100%;
    min-width: 1140px;
    -webkit-box-shadow: 0px 0px 4px 0px rgba(85,85,85,1);
    -moz-box-shadow: 0px 0px 4px 0px rgba(85,85,85,1);
    box-shadow: 0px 0px 2px 0px rgba(85,85,85,1);
    border-radius: 6px;
    font-size: 0.87rem;
}

#scale-reports-table-body {
    display: block;
    overflow-y: auto;
    overflow-x: hidden;
    table-layout: fixed;
}

.scale-reports-table-body-row, .scale-reports-table-head-row {
    border-bottom: 1px solid #cccccc;
    
}

.scale-reports-table-body-row:hover {
    -webkit-box-shadow: inset 0px -1px 2px 0px rgba(169,212,252,1);
    -moz-box-shadow: inset 0px -1px 2px 0px rgba(169,212,252,1);
    box-shadow: inset 0px -1px 2px 0px rgba(169,212,252,1);
    color: #40a3ff !important;
}

#see-more-icon {
    height: 16px;
}

.scale-reports-show-more-icon:hover {
    cursor: pointer;
}

.scale-reports-invoice-icon {
    height: 16px;
    width: 16px;
    margin-left: 5px;
}

.scale-reports-show-more-icon {
    height: 16px;
    width: 16px;
}

#apply-filters-button {
    margin: 0px;
    margin-right: 0;
    margin-left: auto;
    display: block;
}

#reports-filters-form {
    display: grid;
    grid-template-columns: 6fr 1fr;
    min-width: 1140px;
    margin-top: 0.5em;
}

#active-filters-panel {
    margin-top: 1.5em;
}

.filters-title {
    margin: 0px;
}

#table-filters-container {
    padding-top: 8px;
    display:flex;
}

#table-filters-container > *:not(last-child) {
    padding-right: 24px;
}

#applied-filters-container {
    display: grid;
    grid-template-columns: 350px 350px 350px;
}

#supplier-filter, #client-filter, #transporter-filter, #product-filter, #work-point-filter, #status-filter {
    min-width: 272px;
    width: 272px;
}

#create-invoice-form-button {
    margin-left: 10px;
}

#download-scale-report-button {
    margin-left: 10px;
}

.virtualized-table {
    margin-top: 20px;
    box-shadow: 1px 1px 1px 0px rgba(85,85,85,0.25);
}

.virtualized-table-container {
    min-height: 550px;
    height: 100%;
    max-height: 550px;
    border-radius: 6px;
}

.virtualized-table {
    border-radius: 6px;
    border: 1px solid #cccccc;
}

.virtualized-table > div > table {
    border-collapse: collapse;
    width: 100%;
    min-width: 1140px;
    font-size: 0.87rem;
}

.virtualized-table > div > table > thead > tr > td {
    z-index: 0 !important;
    background-color: white;
}

.virtualized-table > div > table > thead {
    z-index: 0 !important; 
    background-color: white;
    border-bottom: 1px solid #cccccc;
}

.virtualized-table > div > table > thead > tr {
    display: block;
}

.virtualized-table > div > table > thead > tr > td {
    display: block;
    text-align:left;
    color: #666;
    padding: 0.4em;
    float: left;
    z-index: -1;
    width: 12.2%;
}

.virtualized-table > div > table > thead > tr > td:nth-child(0) {
    width: 5%;
}

.virtualized-table > div > table > thead > tr > td:nth-child(6),
.virtualized-table > div > table > thead > tr > td:nth-child(8) {
    width: 6.3%;
}

.virtualized-table > div > table > tbody > tr {
    display: block;
    z-index: -1 !important;
    text-align: left;
    align-content: center;
    border-bottom: 1px solid #cccccc;
}

.virtualized-table > div > table > tbody > tr > td {
    height: 21px;
    text-align: left;
    align-content: center;
}


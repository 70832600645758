
#loading-dialog {
    font-size: 1em;
    padding: 100px;
    background-color: rgba(0, 0, 0, 0.0);
    border: none !important;
}

#inside-loading-modal {
    padding: 15px;
    padding-top: 5px;
}

#close-loading-dialog {
    margin-top: 5px;
    position: relative;
    float: right;
    margin-right: 5px;
    border: none;
    opacity: 0.3;
    font-size: 26px;
}

#close-loading-dialog:after {
    display: inline-block;
    content: "\00d7";
}

#close-loading-dialog:hover {
    opacity: 1;
}

#loading-dialog-wrapper {
    background-color: rgb(255, 255, 255);
}

#loading-dialog::backdrop {
    font-size: 1em;
    background: rgba(0, 0, 0, 0.5);
}

.file-downloading-gif {
    height: 64px;
    padding-left: 16px;
}

#download-progress {
    padding: 10px;
}

#download-progress > div {
    width: 100px;
    text-align: center;
}
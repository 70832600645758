.et-file-upload-form {
    padding-top: 20px;
    width: 200px;
    height: 200px;
    position: relative;
}

.file-loading-state{
    position: absolute;
    top: 20px;
    left: 0px;
    z-index: 3;
}

.file-loading-gif-container {
    width: 200px;
    height: 200px;
    background-color: white;
}

.file-loading-gif {
    position: absolute;
    top: 50px;
    left: 50px;
    width: 100px;
    height: 100px;
}

.file-preview{
    position: absolute;
    top: 20px;
    left: 0;
}

.et-file-upload-form input {
    opacity: 0;
    position: relative;
    width: 200px;
    height: 200px;
    z-index: 1;
}

.et-file-upload-form label {
    position: absolute;
    border: 1px dashed #e0e0e0;
    border-radius: 6px;
    height: 158px;
    width: 158px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
    margin-top: -200px;
    text-align: center;
}

.et-drop-file {
    display: block;
    font-weight: 600;
    color: #07074d;
    font-size: 20px;
    margin-bottom: 8px;
}

.file-preview img{
    position: absolute;
    min-width: 200px;
    min-height: 200px;
    width: 200px;
    height: 200px;
    max-width: 200px;
    max-height: 200px;
    object-fit: contain;
}

.file-button-delete {
    width: 24px;
    height: 24px;
    position: absolute;
    left: 176px;
    z-index: 2;
    border: none;
    border-radius: 50%;
    padding: 0px;
    background: transparent;
}

.file-button-delete:hover {
    cursor: pointer;
    background: rgb(248, 248, 248); /* Theo's favorite grey, which is a shade of white actually, lighter than whitesmoke */
}

.file-button-delete img{
    width: 24px;
    height: 24px;
    filter: invert(15%) sepia(94%) saturate(7469%) hue-rotate(1deg) brightness(113%) contrast(114%); /* CSS magic to make a png red */
}
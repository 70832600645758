#payment-dialog {
    font-size: 1em;
    padding: 100px;
    background-color: rgba(0, 0, 0, 0.0);
    border: none !important;
}

#inside-invoice-payment-modal {
    padding: 15px;
    padding-top: 5px;
}

#close-payment-dialog {
    margin-top: 5px;
    position: relative;
    float: right;
    margin-right: 5px;
    border: none;
    opacity: 0.3;
    font-size: 26px;
}

#close-payment-dialog:after {
    display: inline-block;
    content: "\00d7";
}

#close-payment-dialog:hover {
    opacity: 1;
}

#payment-dialog-wrapper {
    background-color: rgb(255, 255, 255);
}

#payment-dialog::backdrop {
    font-size: 1em;
    background: rgba(0, 0, 0, 0.5);
}

.invoice-payment-title, .invoice-payment-total, .invoice-payment-type,
 .invoice-payment-number, .invoice-payment-series, .invoice-payment-encashment-date,
 .invoice-payment-creation-date {
    padding-bottom: 4px;
    padding-top: 4px;
}

#invoice-payment-type-select {
    width: 240px !important;
}

#invoice-payment-print-input {
    margin-right: 8px;
}
.module-name {
    margin: 0;
    line-height: 1.9rem;
}

.menu-item-container {
    display: grid;
	grid-template-columns: 1fr 7fr;
    padding-left: 30px;
}

#details-panel {
    height: 585px;
    width: 0;
    position: fixed;
    z-index: 1;
    top: 140px;
    right: 0;
    background-color: #fff;
    overflow-x: hidden;
    transition: 0.5s;
    -webkit-box-shadow: -2px -1px 4px 0px rgba(170,170,170,1);
    -moz-box-shadow: -2px -1px 4px 0px rgba(170,170,170,1);
    box-shadow: -2px -1px 4px 0px rgba(170,170,170,1);
}

#details-panel h3 {
    margin-top: 0px;
}

#details-panel-header {
    height: 30px;
    margin-top: 20px;
    display: grid;
    grid-template-columns: 5fr 2fr 1fr;
    -webkit-box-shadow: 0px 3px 3px -3px rgba(170,170,170,1);
    -moz-box-shadow: 0px 3px 3px -3px rgba(170,170,170,1);
    box-shadow: 0px 3px 3px -3px rgba(170,170,170,1);
}

#status-container {
    text-align: right;
}

#close-details-panel-img-container {
    padding-right: 10px;
    text-align: right;
}

#details-panel-close-icon:hover {
    cursor: pointer;
}

#details-table {
    margin-top: 30px;
    display:grid;
    grid-template-columns: 1fr 1fr;
}

#details-table-column-2 {
    padding-left: 15px;
    -webkit-box-shadow: -3px 0px 3px -3px rgba(170,170,170,1);
    -moz-box-shadow: -3px 0px 3px -3px rgba(170,170,170,1);
    box-shadow: -3px 0px 3px -3px rgba(170,170,170,1);
}

.info {
    color: #666;
}
#logo-container {
	width: 100%;
	padding: 10% 0% 10% 10%;
}

#logo {
	width: 222px;
	height: 117px;
	object-fit: fill;
}

#adminPanel-container {
    padding: 30px;
    max-height: 100vh;
    overflow-y: auto;
}

#user-section {
    margin-top: 30px;
}

#create-user-form {
    display: table;
}

.adminPanel-form-selector {
    display: table-cell;
    margin-top: 10px;
    margin-left: 10px;
}

#license-keys-table {
    margin-top: 14px;
    border-collapse: collapse;
    width: 100%;
    min-width: 1140px;
    -webkit-box-shadow: 0px 0px 4px 0px rgba(85,85,85,1);
    -moz-box-shadow: 0px 0px 4px 0px rgba(85,85,85,1);
    box-shadow: 0px 0px 2px 0px rgba(85,85,85,1);
    border-radius: 6px;
    font-size: 0.87rem;
}

.license-keys-table-head-cell {
    text-align: left;
}

#license-keys-table-body {
    display: block;
    overflow-y: auto;
    overflow-x: hidden;
    table-layout: fixed;
    height: 250px;
}

#license-keys-table-body {
    table-layout: fixed;
    height: 250px;
    overflow-y: auto;
}

.license-keys-table-body-row, .license-keys-table-head-row {
    border-bottom: 1px solid #cccccc;
    
    clear: both;
}

.license-keys-table-body-row {
    height: 25px;
}

.license-keys-table-body-row:hover {
    background-color: #d6ebff;
}
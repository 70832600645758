#product-data-container {
    width: 100%;
}

.container-margin-top {
    margin-top: 10px;
}

.product-data {
    display: inline-block;
    height: 50px;
    margin-right: 10px;
}

.product-label {
    margin-bottom: 5px;
}

#product-vat-input, #product-unit-price-input {
    width: 80px !important;
}

#product-net-value-input, #product-total-value-input {
    width: 120px !important;
}

#product-name-input {
    width: 270px !important;
}

#product-quantity-input {
    width: 140px;
}

.remove-product-button {
    margin-top: 10px !important;
    height: 25px !important;
  }